import * as React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import Seo from "../components/seo"
import AnchorLink from 'react-anchor-link-smooth-scroll'
import "../appPage.css"

import girl20 from "../images/girls/20.png"
import girl13 from "../images/girls/13.png"
import girl12 from "../images/girls/12.png"
import girl25 from "../images/girls/25.png"
import appstore from "../images/appstore.svg"
import googlePlay from "../images/google-play-badge.png"
import mockSeikaku1 from "../images/mockSeikaku1.png"
import mockSeikaku2 from "../images/mockSeikaku2.png"
import mockSeikaku3 from "../images/mockSeikaku3.png"
import mockStress1 from "../images/mockStress1.png"
import mockStress2 from "../images/mockStress2.png"
import mockStress3 from "../images/mockStress3.png"
import mockCalendar1 from "../images/mockCalendar1.png"
import mockCalendar2 from "../images/mockCalendar2.png"
import mockCalendar3 from "../images/mockCalendar3.png"
import NurseImg3 from "../images/NurseImg3.png"
import release0927 from "../images/release0927.png"

const AppPage = ({ data, location }) => {
  const siteTitle = data.site.siteMetadata.title

  return (
    <Layout location={location} title={siteTitle}>
      <Seo title="アプリについて" />
      <section className="PageTitle" id="pagetop">
        <div class="wrap">
          <img src={ girl20 } className="girls_img"/>
          <img src={ girl13 } className="girls_img"/>
          <div>
            <h2>[　Application　]</h2>
            <h1>NuRseCallアプリを詳しくご紹介</h1>
          </div>
          <img src={ girl12 } className="girls_img"/>
          <img src={ girl25 } className="girls_img"/>
        </div>
        <div className="area">
          <ul class="circles">
            <li></li><li></li><li></li><li></li><li></li><li></li><li></li><li></li><li></li><li></li>
          </ul>
        </div>
      </section>
      <section className="AppPage">
        <div className="AppIndex wrap">
          <h2>ナースのための機能を、<br className="pcDispNone"></br>ひとつのアプリで。</h2>
          <div className="cont">
            <ul>
              <AnchorLink href="#AppSeikakuShindan" offset="50"><li className="bg-aqua"><p>性格診断</p></li></AnchorLink>
              <AnchorLink href="#AppStressCheck" offset="50"><li className="bg-pink"><p>ストレス<br></br>チェック</p></li></AnchorLink>
              <AnchorLink href="#AppCalendar" offset="50"><li className="bg-yellow"><p>シフト<br></br>カレンダー</p></li></AnchorLink>
            </ul>
            <p className="download-text">ダウンロードはこちら</p>
            <div className="download-box-center mb-10">
              <a href="https://apps.apple.com/jp/app/nursecall/id1539839928" target="_blank">
                <img src={ appstore } className="appstore-150 ml-10" alt="App Storeからダウンロード" />
              </a>
              <a target="_blank" href='https://play.google.com/store/apps/details?id=com.nurse_support.nursecall&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1'>
                <img alt='Google Play で手に入れよう' className="appstore-150 ml-10" src={ googlePlay }/>
              </a>
            </div>
          </div>
        </div>

        <div className="AppSeikakuShindan" id="AppSeikakuShindan">
          <div className="wrap">
            <h2>性格タイプ診断</h2>
            <h3>
            ナースエゴグラムで価値観や思考・行動様式、趣味・趣向などから、２９タイプの中からあなたの性格に最も近いタイプを判定します。<br>
            </br>あなたの性格特性や、あなたへのアドバイスを見ることができます。
            </h3>
            <div className="mock">
              <img src={ mockSeikaku1 } alt="簡単な質問に答えて" />
              <img src={ mockSeikaku2 } alt="診断結果をチェック" />
              <img src={ mockSeikaku3 } alt="SNSで皆にシェアしよう" />
            </div>
            <h4>性格タイプは２９種類！あなたはどの性格タイプ？</h4>
            <ul className="GirlsIconList">
              <li></li><li></li><li></li><li></li><li></li><li></li><li></li><li></li><li></li><li></li><li></li><li></li><li></li><li></li><li></li><li></li><li></li><li></li><li></li><li></li><li></li><li></li><li></li><li></li><li></li><li></li><li></li><li></li><li></li>
            </ul>
          </div>
        </div>

        <div className="AppStressCheck" id="AppStressCheck">
          <div className="wrap">
            <h2>ストレス診断</h2>
            <h4>忙しくて自分のストレスチェックを疎かにしがちな看護師のために設計しました</h4>
            <h3>ひと月に４回、簡単な質問に答えるだけでその月の診断ができます。全体的なストレスレベル、ストレス反応、ストレス要因がシンプルな
グラフで前月比較表示されます。</h3>
          </div>
          <div className="mock">
            <img src={ mockStress1 } alt="ホーム画面｜自分のストレスレベルをチェックしよう！月４回の定期的なチェック（短いアンケート回答）で自分の心と身体の状態を教えてくれます。" />
            <img src={ mockStress2 } alt="簡単な質問に数問答えるだけです" />
            <img src={ mockStress3 } alt="毎月、最後の質問に答えた段階で、診断結果が更新されます。ストレスの状態を見やすく表示します" />
          </div>
          <div className="AppStressCheckMerit wrap">
            <p><b>ストレスを可視化</b>することで、自分の状態を客観的に捉えることができます。</p>
            <p>自分の<b>ストレス度合いを知る</b>だけでもストレス軽減につながります。</p>
            <p><b>ストレスの原因が何かを知る</b>ことで対処しやすくなります。</p>
          </div>
        </div>

        <div className="AppCalendar" id="AppCalendar">
          <div className="wrap">
            <h2>シフト共有カレンダー</h2>
            <h4 className="pink">2021年9月27日リリース</h4>
            <h3>NuRseCallアプリを日常でも使えるアプリにするため<br></br>看護師さん用のシフト管理カレンダー機能を追加しました！</h3>
            <div className="aboutCalendar mock">
              <img src={ mockCalendar1 } alt="見やすいカレンダー" />
              <div className="CalendarMerit No1">
                <h4>見やすいカレンダー</h4>
                <p>シフトも、プライベートの予定もこのカレンダーひとつで管理できます。</p>
                <p>１つの画面でシフト一覧や、その日の予定を見ることができます。</p>
                <p>シフトは帯表示、予定はアイコンで表示されます。アイコンは随時追加されます。</p>
                <p>カレンダーは月・週・リストの３種類で、スケジュールを確認しやすくしています。</p>
              </div>
            </div>
            <div className="aboutCalendar mock">
              <div className="CalendarMerit No2">
                <h4>簡単便利なシフト入力</h4>
                <p>シフトをスタンプ感覚で連続で入力でき、短時間でシフトの入力ができます。</p>
                <p>複雑なシフトにも対応！<br></br>1日に最大3件までシフトを入力できます。</p>
                <p>登録したシフト毎に、メモや勤務メンバーを登録することができます。</p>
                <p>シフトの表示内容やカラーは編集ができるので、よく利用する予定やタスクを登録したりなど、色々な使い方ができます。</p>
              </div>
              <img src={ mockCalendar2 } alt="簡単便利なシフト入力" />
            </div>
            <div className="aboutCalendar mock">
              <img src={ mockCalendar3 } alt="仲間とシフトを共有" />
              <div className="CalendarMerit No3">
                <h4>仲間とシフトを共有</h4>
                <p>職場はもちろん、同期の仲間や友人、家族とシフトを共有することができます。</p>
                <p>共有するグループは作成・招待・参加ができ、管理者機能で安全に運用できます。</p>
                <p>共有しているシフトはリアルタイムで更新されます。</p>
                <p>グループの参加者はアイコンや名前を自由に設定できます。ニックネームにしたり、お気に入りの写真をアイコンにしてもOK!</p>
              </div>
            </div>
          </div>
        </div>

        <div className="CTA">
          <div className="wrap">
            <div>
              <h2>NuRseCall<small>（ナースコール）</small>は<br />今後も看護師さんの役に立つ<br />機能を追加していきます。</h2>
              <p className="download-text">ダウンロードはこちら</p>
              <div className="download-box-center mb-10">
                <a href="https://apps.apple.com/jp/app/nursecall/id1539839928" target="_blank">
                  <img src={ appstore } className="appstore-150 ml-10" alt="App Storeからダウンロード" />
                </a>
                <a target="_blank" href='https://play.google.com/store/apps/details?id=com.nurse_support.nursecall&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1'>
                  <img alt='Google Play で手に入れよう' className="appstore-150 ml-10" src={ googlePlay }/>
                </a>
              </div>
            </div>
            <img src={ NurseImg3 } alt="看護師のためのアプリ" className="NurseImg"/>
          </div>
          <div className="area">
            <ul class="circles">
              <li></li><li></li><li></li><li></li><li></li><li></li><li></li><li></li><li></li><li></li>
            </ul>
          </div>
        </div>
        

      </section>
    </Layout>
  )
}

export default AppPage

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`
